import React from 'react';
import Contact from '../../components/contact/contact';
import Layout from '../../layouts';
import Seo from '../../layouts/common/seo';

const ContactPage = () => {
  return (
    <>
      <Seo title="Contact US - Better Way of Life." description="Contact Us Via Email at hhes@hhesbooks.com" />
      <Layout>
        <Contact />
      </Layout>
    </>
  );
};

export default ContactPage;
