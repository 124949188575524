import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'gatsby';

import LeftArrowContact from '@assets/contact/left-arrow';
import ContactComponent from '../contact-component/contact-component';
import BlogButton from '../reusable-buttons/blog-button';
import LanguageContext from '../../context/language-context';
import BackDrop from '../backdrop/backdrop';
import CloseNotification from '@assets/svg/close-notification.svg';
import Tick from '@assets/svg/tick-mark.svg';
import crossmark from '../../assets/browse-books/cross-mark-icon-8.jpg';

const Contact = () => {
  const CONTACT_FORM_API = process.env.GATSBY_CONTACT_FORM_API;
  const { contentLanguage } = useContext(LanguageContext);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [notificationText, setNotificationText] = useState({ desc: '', greeting: '' });
  const [tickIcon, setTickIcon] = useState(true);

  const contactFormToSend = {
    contact_name: contactFormData.name.trim(),
    contact_email: contactFormData.email,
    contact_message: contactFormData.message,
  };

  const handleChange = (event) => {
    const input = event.target;

    setContactFormData((prevState) => {
      return {
        ...prevState,
        [input.name]: input.value,
      };
    });
  };

  const handleSendMessage = async () => {
    setIsLoading(true);
    const response = await fetch(CONTACT_FORM_API, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(contactFormToSend),
    });
    if (response.status == '200') {
      setShowNotification(true);
      setTickIcon(true);
      setNotificationText({ desc: 'Your message has been sent successfully.', greeting: 'Thank You!' });
    } else {
      setShowNotification(true);
      setTickIcon(false);
      setNotificationText({ desc: 'Opps something went wrong. Make sure email is valid.', greeting: 'Sorry!' });
    }
    setIsLoading(false);

    console.error(response);
  };

  const contentResources = {
    eng: {
      titleFirstHalf: 'Connect ',
      titleSecondHalf: 'with us.',

      sendMessage: 'Send Message',
      home: 'Home',
      contact: 'Contact',
      name: 'Name',
      email: 'Email',
      yourMessage: 'Your Message',
      yourFullName: 'Your Full Name',
      yourEmail: 'Your Email',
      textAreaMessage: 'If You Like To Add Anything',
      descText: `We are concerned about your health and benefits. Through our healthy living book store, we will assist you in adapting to a healthy way of life.  Please tell us a little about yourself.  We will always be happy to assist you with your questions. We'll get back to you right away.`,
    },
    esp: {
      titleFirstHalf: 'Conéctate ',
      titleSecondHalf: 'con  nosotros.',

      sendMessage: 'Enviar mensaje',
      home: 'Hogar',
      contact: 'contacto',
      name: 'Nombre',
      email: 'Correo electrónico',
      yourMessage: 'Tu mensaje',
      yourFullName: 'Tu nombre completo',
      yourEmail: 'Tu correo electrónico',
      textAreaMessage: 'Si desea agregar algo',
      descText:
        'Tus pensamientos y tu salud son importantes. Cuéntanos un poco sobre ti. Cuál es la mejor manera de comunicarnos con tigo. Nos comunicaremos con tigo de inmediato.',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);

  useEffect(() => {
    if (contactFormData.name === '' || contactFormData.email === '' || contactFormData.message === '') {
      setSendButtonDisabled(true);
    } else {
      setSendButtonDisabled(false);
    }
  }, [contactFormData]);
  return (
    <div className="contact-page-container common-container u-margin-top-lg u-margin-bottom-xl">
      <div className="contact-page-navigation">
        <Link to="/">
          <span className="contact-page-nav dim">{currentContent?.home}</span>
        </Link>
        <span className="contact-page-nav">
          <LeftArrowContact />
        </span>
        <span className="contact-page-nav">{currentContent?.contact}</span>
      </div>
      <div className="contact-page-body">
        <div className="contact-info">
          <div className="contact-info-heading">
            <span className="h1-black">{currentContent?.titleFirstHalf}</span>
            <span className="h1-modified">{currentContent?.titleSecondHalf}</span>
          </div>
          <div className="contact-info-text">{currentContent?.descText}</div>

          <ContactComponent />
          {/* CONTACT*/}
        </div>
        <div className="contact-form">
          <form className="contact-form-holder">
            <div className="input-field-holder">
              <label htmlFor="name-user">{currentContent?.name}</label>
              <br />
              <input
                className="contact-form-inputtext"
                id="name-user"
                type="text"
                placeholder={currentContent?.yourFullName}
                name="name"
                onChange={handleChange}
                value={contactFormData.name}
              />
              <br />
            </div>

            <div className="input-field-holder">
              <label htmlFor="user-email">{currentContent?.email}</label>
              <br />
              <input
                className="contact-form-inputtext"
                id="user-email"
                type="email"
                placeholder={currentContent?.yourEmail}
                name="email"
                onChange={handleChange}
                value={contactFormData.email}
              />
              <br />
            </div>

            <div className="input-textbox-holder">
              <label htmlFor="feedback">{currentContent?.yourMessage}</label>
              <br />
              <textarea
                className="contact-form-textbox"
                id="feedback"
                placeholder={currentContent?.textAreaMessage}
                name="message"
                onChange={handleChange}
                value={contactFormData.message}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="contact-page-button" onClick={() => (sendButtonDisabled ? {} : handleSendMessage())}>
        <BlogButton buttonText={currentContent?.sendMessage} disable={sendButtonDisabled} isLoading={isLoading} />
      </div>
      <div className={showNotification ? 'form-submission-notification' : 'hide'}>
        <div className="close-icon-notification">
          <img
            className="cursor-pointer"
            onClick={() => setShowNotification(!showNotification)}
            src={CloseNotification}
          />
        </div>
        <div className="tick-mark-notification">{tickIcon ? <img src={Tick} /> : <img src={crossmark} />}</div>
        <h4>{notificationText.desc}</h4>
        <h6>{notificationText.greeting}</h6>
      </div>
      <div
        className={showNotification ? 'show' : 'hide'}
        onClick={() => {
          setShowNotification(!showNotification);
        }}
      >
        <BackDrop />
      </div>
    </div>
  );
};

export default Contact;
